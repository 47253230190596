import { createRouter, createWebHistory } from 'vue-router';
import maintenanceMode from '../views/maintenanceMode.vue';

const routes = [
    {
        path: '/',
        name: 'Maintenance',
        meta: { title: 'Maintenance - NutsBetting' },
        component: maintenanceMode
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// Écoute l'événement de navigation avant chaque changement de route
router.beforeEach((to, from, next) => {
    // Détermine le titre de la route, ou utilise un titre par défaut si non spécifié
    const title = to.meta.title || 'NutsBetting';

    // Met à jour le titre de la page immédiatement
    document.title = title;

    // Continue la navigation
    next();
});

export default router;
