<template>
    <!-- Conteneur principal qui occupe toute la hauteur de l'écran -->
    <div class="min-h-screen flex items-center justify-center bg-gray-900 py-16 p-4 sm:py-24">
        <div class="mx-auto sm:px-4 lg:px-6" style="max-width: 96rem;">
            <div class="flex flex-col lg:flex-row gap-8">
                <!-- Bloc principal à gauche, affiché quand showBack est false -->
                <div v-if="!showBack"
                    :class="['bg-white/5 p-6 rounded-3xl border border-dashed border-emerald-200 shadow-lg flex flex-col items-center justify-center transition-opacity duration-500 ease-in-out', showBack ? 'lg:w-6/12' : 'lg:w-8/12']">
                    <div class="flex flex-col lg:flex-row gap-6 items-center">
                        <!-- Image de maintenance -->
                        <img class="h-48 lg:h-64 w-full lg:w-auto rounded-2xl object-cover shadow-xl lg:aspect-square"
                            :src="maintenanceImage" alt="Maintenance" />
                        <div class="flex-auto text-center lg:text-left">
                            <!-- Titre et description -->
                            <h1 class="text-xl text-center text-yellow-1000 sm:text-3xl">
                                Application en cours de développement...
                            </h1>
                            <p class="mt-4 text-md text-gray-300 text-center">
                                Nous sommes ravis de vous annoncer que notre application web, <b>NutsBetting</b>, est
                                actuellement en développement et progresse en douceur vers un avenir passionnant.
                            </p>
                            <!-- Bouton pour afficher les statistiques -->
                            <div class="flex justify-center mt-6">
                                <button @click="showBack = true"
                                    class="bg-purple-400 hover:bg-purple-500 text-black p-2 rounded-lg shadow-lg">
                                    Explorer mes statistiques
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Contenu arrière avec le graphique ApexChart, affiché quand showBack est true -->
                <div v-if="showBack"
                    :class="['bg-gray-800 text-gray-400 p-6 rounded-3xl border border-dashed border-emerald-200 shadow-lg flex items-center justify-center transition-opacity duration-500 ease-in-out', 'w-full', 'lg:w-6/12']">
                    <!-- Conteneur pour le graphique -->
                    <div id="chart" class="w-full h-64"></div>
                </div>


                <!-- Bloc du bouton Stripe à droite -->
                <div
                    :class="['bg-gray-800 p-6 rounded-3xl border border-dashed border-emerald-200 shadow-lg flex flex-col items-center justify-center', showBack ? 'lg:w-6/12' : 'lg:w-5/12']">
                    <div class="w-full max-w-xs">
                        <stripe-buy-button buy-button-id="buy_btn_1PufavLSejocMVHUN8VSORp0"
                            publishable-key="pk_live_51KtE48LSejocMVHUJzjOneK6SamM1fQ3H0PxzghelaAJOYRvajClCyRYUosfAm98rHeYWSS1909SkXGuSf8447PD002ciE30wp">
                        </stripe-buy-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import { ref, watch, nextTick } from 'vue';  // Importation des fonctions de Vue.js
import maintenanceImage from '../assets/maintenance/photo.jpg';  // Image de maintenance
import ApexCharts from 'apexcharts';  // Importation de la librairie ApexCharts
import axios from '../axios';  // Importation d'axios pour les requêtes HTTP

// Variables réactives
const showBack = ref(false);  // Contrôle de l'affichage du graphique
const chartData = ref([]);  // Données pour le graphique
const chartCategories = ref([]);  // Catégories pour l'axe X (IDs des paris)

// Bankroll de départ
const startingBankroll = 1000;

// Fonction pour récupérer les données depuis l'API
const fetchChartData = async () => {
    try {
        const { data } = await axios.post('/api/test.php');

        //console.log("Données récupérées :", data);


        if (Array.isArray(data)) {
            const traitementData = data.sort((a, b) => a.id - b.id);

            // Calculer la variation de la bankroll en partant de la bankroll de départ
            let bankroll = startingBankroll;
            const bankrollEvolution = traitementData.map(item => {
                bankroll += item.benefice;
                return bankroll;
            });

            chartData.value = bankrollEvolution;  // La courbe représente l'évolution de la bankroll
            chartCategories.value = traitementData.map(item => item.id);

            //console.log("Données de graphique (chartData) :", chartData.value);
            //console.log("Catégories de graphique (chartCategories) :", chartCategories.value);
        } else {
            console.error("Les données récupérées ne sont pas un tableau :", data);
        }
    } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
    }
};

// Fonction pour créer et afficher le graphique
const renderChart = () => {
    const options = {
        series: [
            {
                name: 'Variation de la bankroll',
                data: chartData.value
            },
        ],
        chart: {
            type: 'area',
            height: 350,
            width: '100%',
            stacked: false,
            background: '#333333',
            toolbar: {
                show: true,
                tools: {
                    download: false,
                    selection: true,
                    zoom: false,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: true,
                    customIcons: []
                }
            }
        },
        colors: ['#a78bfa'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'monotoneCubic'
        },
        fill: {
            type: 'gradient',
            gradient: {
                opacityFrom: 0.6,
                opacityTo: 0.8,
            }
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            fontWeight: 400
        },
        xaxis: {
            type: 'category',
            categories: chartCategories.value,
            title: {
                text: 'Référence du pari placé',
                style: {
                    fontWeight: 400
                }
            }
        },
        yaxis: {
            min: 1000,  // Assurez-vous que l'axe Y commence à 0
            title: {
                text: 'Variation de la bankroll',
                style: {
                    fontWeight: 400
                }
            },
            labels: {
                formatter: function (value) {
                    return value + "€";
                }
            }
        },
        tooltip: {
            y: {
                formatter: function (value) {
                    return value + "€";
                }
            }
        },
        theme: {
            mode: 'dark'
        },
        annotations: {
            yaxis: [
                {
                    y: startingBankroll,
                    borderColor: '#000',
                    label: {
                        borderColor: '#000',
                        style: {
                            color: '#fff',
                            background: '#000'
                        },
                        text: 'Bankroll de départ (1000€)'
                    }
                }
            ]
        }
    };

    const chart = new ApexCharts(document.querySelector("#chart"), options);
    chart.render();
};

// Surveillance de la variable showBack pour déclencher la récupération des données et le rendu du graphique
watch(showBack, async (newValue) => {
    if (newValue) {
        await fetchChartData();  // Récupération des données
        nextTick(() => {
            renderChart();  // Rendu du graphique après récupération des données
        });
    }
});
</script>

<style>
#chart {
    border-radius: 15px;
    overflow: hidden;
    background-color: #333333;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 640px) {
    #chart {
        width: 100%;
        height: 300px;
    }
}

@media (min-width: 641px) {
    #chart {
        width: 800px;  /* Largeur définie pour les écrans plus grands */
        height: 350px;  /* Hauteur standard */
    }
}
</style>
